<template>
  <div class="video-roi">
    <canvas id="canvas" style="z-index: 10; background-color: transparent;"></canvas>
    <div v-if="!mseUrl" ref="noVideo" class="no-stream-info">No Streaming Available</div>
    <video v-show="mseUrl" ref="video" class="video" loop muted 
      @click.prevent @loadedmetadata="handleLoadedMetadata" >
      <source :src="mseUrl"/>
    </video>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import mse from '@/api/mse.js'
import { fabric } from 'fabric'

export default {
  name: 'VideoRoiLpr',
  data() {
    return {
      player: null,
      playPromise: null,
      canvas: null,
      roi: null,
      indentRoi: null,
      targetRoi: null,
      targetRoiText: null,
      videoW: 0,
      videoH: 0,
      targetRoiW: 0,
      videoRealWidth: 0,
      minLprRatio: 1,
      minLprRoiW: 30,
      maxLprRoiW: 500,
    }
  },
  computed: {
    ...mapState(['liveList']),
    ...mapState('aibox', ['aiRunOn', 'isEdit', 'isEditRoi']),
    ...mapState('recgLpr', ['currDefaultSetting']),
    ...mapGetters('recgLpr', ['userIndex', 'Roi']),
    mseUrl() {
      let live = this.liveList.find(live => live.index == this.userIndex)
      if (live) {
        return live.mseUrl
      } else return null 
    },
    lprRatio: {
      get() {
        if (this.aiRunOn === 'device') {
          return this.$store.getters['recgLpr/lprSizeRatio']
        } else {
          const task = this.$store.getters['aibox/aiBoxTask']
          const useModelType = 'lpr' + task.config.aiModelType.charAt(0).toUpperCase() + task.config.aiModelType.slice(1)
          return task.config[useModelType].lprSizeRatio
        }
      },
      set(val) {
        const module = this.aiRunOn === 'device' ? 'recgLpr' : 'aibox'
        this.$store.commit(`${module}/updateLprSizeRatio`, val)
      }
    },
    ROI: {
      get() {
        if (this.aiRunOn === 'device')
          return this.Roi
        else {
          const task = this.$store.getters['aibox/aiBoxTask']
          const useModelType = 'lpr' + task.config.aiModelType.charAt(0).toUpperCase() + task.config.aiModelType.slice(1)
          return task.config[useModelType].roi
        }
      },
      set(val) {
        const module = this.aiRunOn === 'device' ? 'recgLpr' : 'aibox'
        this.$store.commit(`${module}/updateROI`,val)
      }
    },
    indent: {
      get() {
        if (this.aiRunOn === 'device')
          return this.$store.getters['recgLpr/indent']
        else {
          const task = this.$store.getters['aibox/aiBoxTask']
          const useModelType = 'lpr' + task.config.aiModelType.charAt(0).toUpperCase() + task.config.aiModelType.slice(1)
          return task.config[useModelType].indent
        }
      },
      set(val) {
        const module = this.aiRunOn === 'device' ? 'recgLpr' : 'aibox'
        this.$store.commit(`${module}/updateIndent`,val)
      }
    },
    canEditRoi() {
      if (this.aiRunOn === 'device') {
        if (!this.isEdit || this.currDefaultSetting === 1) return false
        return this.isEditRoi
      } else {
        return this.isEdit && this.isEditRoi
      }
    },
    canEditTargetRoi() {
      if (this.aiRunOn === 'device') {
        if (!this.isEdit || this.currDefaultSetting === 1) return false
        return !this.isEditRoi
      } else {
        return !this.isEditRoi
      }
    },
  },
  watch: {
    isEdit() {
      this.handleSelectable()
    },
    currDefaultSetting() {
      this.roi.hasControls = this.currDefaultSetting === 0
      this.targetRoi.hasControls = this.currDefaultSetting === 0
      this.handleSelectable()
    },
    isEditRoi() {
      this.handleSelectable()
    },
    lprRatio() {
      this.updateTargetRoiByRatio()
    },
    ROI: {
      handler() {
        this.roi.set({
          left: this.videoW * this.ROI.x1,
          top: this.videoH * this.ROI.y1,
          width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4,
          height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4,
          scaleX: 1,
          scaleY: 1,
        })

        const indentW = this.videoW * (this.ROI.x2 - this.ROI.x1) * this.indent

        this.indentRoi.set({
          left: this.videoW * this.ROI.x1 + indentW,
          top: this.videoH * this.ROI.y1 + indentW,
          width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4 - indentW * 2,
          height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4 - indentW * 2,
          scaleX: 1,
          scaleY: 1,
        })

        this.roi.setCoords()
        this.indentRoi.setCoords()
        this.canvas.renderAll()
      },
      deep: true,
    },
    indent() {
      const indentW = this.videoW * (this.ROI.x2 - this.ROI.x1) * this.indent

      this.indentRoi.set({
        left: this.videoW * this.ROI.x1 + indentW,
        top: this.videoH * this.ROI.y1 + indentW,
        width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4 - indentW * 2,
        height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4 - indentW * 2,
      })
      this.canvas.renderAll()
    },
  },
  mounted() {
    this.onInit()
    window.addEventListener('resize', this.windowResize)
  },
  beforeDestroy() {
    this.pauseVideo()
    window.removeEventListener('resize', this.windowResize)
    if (this.canvas) this.canvas.clear() // remove all objects
  },
  methods: {
    ...mapMutations('recgLpr', ['updateLprSizeRatio', 'updateROI']),
    onInit() {
      this.playVideo()
      if (!this.canvas)
        this.canvas = new fabric.Canvas('canvas')
      const [videoW, videoH] = this.getVideoWidthHeight()
      this.canvas.setWidth(videoW)
      this.canvas.setHeight(videoH)
      this.videoW = videoW
      this.videoH = videoH

      this.roi = new fabric.Rect({
        name: 'roi',
        left: this.videoW * this.ROI.x1,
        top: this.videoH * this.ROI.y1,
        fill: 'transparent',
        stroke: '#ffffff',
        strokeWidth: 2,
        width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4,
        height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4,
        cornerSize: 10,
        cornerColor: '#ffffff',
        transparentCorners: false,
        lockRotation: true, // 禁止旋轉
        selectable: this.isEdit && this.isEditRoi ? true : false,
        evented: this.isEdit && this.isEditRoi,
      })

      const indentW = this.videoW * (this.ROI.x2 - this.ROI.x1) * this.indent

      this.indentRoi = new fabric.Rect({
        name: 'indentRoi',
        left: this.videoW * this.ROI.x1 + indentW,
        top: this.videoH * this.ROI.y1 + indentW,
        fill: 'transparent',
        stroke: '#F94144',
        strokeWidth: 1,
        width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4 - indentW * 2,
        height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4 - indentW * 2,
        cornerSize: 0,
        cornerColor: 'transparent',
        transparentCorners: true,
        lockRotation: true, // 禁止旋轉
        selectable: false,
        evented: false,
      })

      this.targetRoiW = this.videoW * this.lprRatio / 100
      const initLeft = (this.videoW - this.targetRoiW) / 2
      const initTop = (this.videoH - this.targetRoiW) / 2

      this.targetRoi = new fabric.Rect({
        name: 'targetRoi',
        left: initLeft,
        top: initTop,
        fill: '#F9414433',
        stroke: '#F94144',
        strokeWidth: 2,
        width: this.targetRoiW,
        height: this.targetRoiW / 2, 
        hasControls: this.isEdit,
        cornerSize: 10,
        cornerColor: '#F94144',
        transparentCorners: false,
        lockRotation: true, // 禁止旋轉
        lockUniScaling: true, // 等比例縮放
        selectable: this.isEdit ? true : false,
        evented: this.isEdit,
      })
      
      const text = new fabric.Text(`${this.$t('ai_lpr_size_ratio')/*最小車牌比例*/}：${this.lprRatio}%`, {
        name: 'lprText',
        left: 10,
        top: 3,
        fontSize: 16,
        fill: '#ffffff',
      })

      const rect = new fabric.Rect({
        left: 0,
        top: 0,
        rx: 4,
        ry: 4,
        fill: '#191919',
        stroke: '#ffffff',
        strokeWidth: 1,
        width: text.width + 20,
        height: text.height + 6,
        lockRotation: true, // 禁止旋轉
        lockUniScaling: true, // 等比例縮放
        selectable: this.isEdit ? true : false,
        evented: this.isEdit,
      })

      this.targetRoiText = new fabric.Group([rect, text], {
        name: 'targetRoiText',
        left: initLeft + this.targetRoiW + 10,
        top: initTop,
        selectable: false,
        evented: false,
      })

      this.roi.setControlVisible('mtr', false) // 隱藏旋轉控制點
      this.targetRoi.setControlVisible('mtr', false) // 隱藏旋轉控制點
      this.targetRoiText.setControlVisible('mtr', false) // 隱藏旋轉控制點

      this.canvas.add(this.roi)
      this.canvas.add(this.indentRoi)
      this.canvas.add(this.targetRoi)
      this.canvas.add(this.targetRoiText)
      this.canvas.renderAll()
      
      this.targetRoi.on('mouseover',() => {
        this.targetRoiText.visible = true
        this.canvas.renderAll()
      })
      this.targetRoi.on('mouseout',() => {
        this.targetRoiText.visible = false
        this.canvas.renderAll()
      })
      
      this.canvas.on('mouse:down', this.onMouseDown)
      this.canvas.on('object:scaling', this.onObjectScaling)
      this.canvas.on('object:moving', this.onObjectMoving)
      this.canvas.on('object:modified', this.onObjectModified)
    },
    handleLoadedMetadata(event) {
      this.videoRealWidth = event.target.videoWidth // video 實際寬度

      // 車牌比例範圍, 改為 1%-50% (預設 5%), 且寬要 >= 30 pixel 
      this.minLprRatio = Math.max(1, Math.ceil(30 / this.videoRealWidth * 100))
      this.minLprRoiW = Math.max(30, Math.ceil(this.videoRealWidth * 0.01))
      this.maxLprRoiW = Math.ceil(this.videoRealWidth * 0.5)
    },
    handleSelectable() {
      if (!this.roi || !this.targetRoi) return
      this.roi.hasControls = this.canEditRoi
      this.roi.selectable = this.canEditRoi
      this.roi.evented = this.canEditRoi
      this.targetRoi.hasControls = this.canEditTargetRoi
      this.targetRoi.selectable = this.canEditTargetRoi
      this.targetRoi.evented = this.canEditTargetRoi

      if (!this.isEdit || (this.aiRunOn === 'device' && this.currDefaultSetting === 1)) {
        this.canvas.discardActiveObject()
      } else {
        if (this.isEditRoi)
          this.canvas.setActiveObject(this.roi)
        else
          this.canvas.setActiveObject(this.targetRoi)
      }
      
      this.canvas.renderAll()
    },
    windowResize() {
      if (this.canvas) this.canvas.clear() // remove all object
      this.onInit()
    },
    getVideoWidthHeight() {
      let videoW = this.$refs.video.clientWidth > 0 ? this.$refs.video.clientWidth : this.$refs.noVideo.clientWidth
      let videoH = this.$refs.video.clientHeight > 0 ? this.$refs.video.clientHeight : this.$refs.noVideo.clientHeight
      let realVW = 0
      let realVH = 0
      if (videoW / videoH >= 16 / 9) {
        realVH = videoH
        realVW = Math.round(realVH * 16 / 9)
      } else {
        realVW = videoW
        realVH = Math.round(videoW * 9 / 16)
      }
      return [realVW, realVH]
    },
    playVideo() {
      if (!this.mseUrl) return
      this.player = new mse(this.$refs.video, this.mseUrl)
      this.player.startup()
      this.playPromise = this.$refs.video.play()
    },
    pauseVideo() {
      if (this.player) this.player = null
      if (this.playPromise) {
        this.playPromise.then(() => {
          if (this.$refs.video) this.$refs.video.pause()
        })
        .catch(error => {
          console.log('Pause video error: ', error)
        })
      }
    },
    onMouseDown(event) {
      if (event.target?.name === 'roi') {
        const pointer = this.canvas.getPointer(event.e)
        const x = pointer.x
        const y = pointer.y
        if (x >= this.targetRoi.left && x <= this.targetRoi.left + this.targetRoi.width && y >= this.targetRoi.top && y <= this.targetRoi.top + this.targetRoi.height) {
          this.canvas.setActiveObject(this.targetRoi)
        }
      }
    },
    onObjectMoving(event) {
      if (event.target.name === 'roi') {
        this.indentRoi.visible = false
      }

      if (event.target.name === 'targetRoi') {
        this.updateTargetRoiText()
      }
    },
    onObjectScaling(event) {
      if (event.target.name === 'roi') {
        this.indentRoi.visible = false
      }

      if (event.target.name === 'targetRoi') {
        const rect = event.target
        if (this.videoRealWidth === 0) this.videoRealWidth = this.videoW // 沒有 video 實際寬度時, 以 video 寬度為基準
        const scale = this.videoW / this.videoRealWidth // 縮放比例
        // minLprRoiW, maxLprRoiW 以 video 實際寬度為基準, 所以要乘上 scale
        if (rect.width * rect.scaleX < this.minLprRoiW * scale) {
          rect.set({
            scaleX: this.minLprRoiW * scale / rect.width,
            scaleY: this.minLprRoiW * scale / rect.width,
          })
        } else if (rect.width * rect.scaleX > this.maxLprRoiW * scale) {
          rect.set({
            scaleX: this.maxLprRoiW * scale / rect.width,
            scaleY: this.maxLprRoiW * scale / rect.width,
          })
        }
        
        this.handleLprRatio()
        this.updateTargetRoiText()
      }
    },
    onObjectModified(event) {
      if (event.target.name === 'roi') {
        this.updateRoiData(event)
        this.indentRoi.visible = true
      }
    },
    updateRoiData(event) {
      let x1 = event.target.aCoords.tl.x / this.videoW
      let y1 = event.target.aCoords.tl.y / this.videoH
      let x2 = event.target.aCoords.br.x / this.videoW
      let y2 = event.target.aCoords.br.y / this.videoH
      
      x1 = x1 < 0 ? 0 : x1
      y1 = y1 < 0 ? 0 : y1
      x2 = x2 > 1 ? 1 : x2
      y2 = y2 > 1 ? 1 : y2 
      
      this.ROI = { x1, y1, x2, y2 }
    },
    handleLprRatio() {
      const ratio = Math.ceil(this.targetRoi.width * this.targetRoi.scaleX / this.videoW * 100)
      if (ratio < this.minLprRatio) this.lprRatio = this.minLprRatio
      else if (ratio > 50) this.lprRatio = 50
      else this.lprRatio = ratio
    },
    updateTargetRoiText() {
      this.targetRoiText.left = this.targetRoi.left + this.targetRoi.width * this.targetRoi.scaleX + 10
      this.targetRoiText.top = this.targetRoi.top

      const obj = this.targetRoiText.getObjects().find(item => item.name === 'lprText')
      if (obj) obj.set({ text: `${this.$t('ai_lpr_size_ratio')/*最小車牌比例*/}：${ this.lprRatio }%` })
    },
    updateTargetRoiByRatio() {
      const newRoiW = Math.round(this.videoW * this.lprRatio / 100)
      this.targetRoi.set({
        width: newRoiW,
        height: newRoiW / 2,
        scaleX: 1,
        scaleY: 1
      })
      
      this.targetRoiText.left = this.targetRoi.left + newRoiW + 10
      this.targetRoiText.top = this.targetRoi.top
      
      const obj = this.targetRoiText.getObjects().find(item => item.name === 'lprText')
      if (obj) 
        obj.set({ 
          text: `${this.$t('ai_lpr_size_ratio')/*最小車牌比例*/}：${ this.lprRatio }%`,
          visible: true,
          selectable: true 
        })
      
      this.canvas.setActiveObject(this.targetRoi)
      this.canvas.bringToFront(this.targetRoiText)
      this.canvas.bringToFront(this.targetRoi)
      this.canvas.renderAll()
    },
  }
}
</script>

<style lang="scss" scoped>
.video-roi {
  width: 100%;
  height: 100%;
  color: #191919;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-stream-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #666666;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #010101;
  display: block;
  // z-index: -1;
}
</style>